<template>
  <div>
    <div class="row mb-2">
      <div class="col">
        <div class="card bg-100 shadow-none border">
          <div class="row gx-0 flex-between-center">
            <div class="col-sm-auto d-flex align-items-center">
              <img class="ms-n2" src="assets/img/illustrations/crm-bar-chart.png" alt="" width="90" />

              <div>
                <h6 class="text-primary fs--1 mb-0">{{ getTimegreetings() }}</h6>

                <h4 class="text-primary fw-bold mb-0">
                  <span class="text-info fw-medium">{{ user.first_name }}</span>
                  {{ user.last_name }}
                </h4>
              </div>

              <img class="ms-n4 d-md-none d-lg-block" src="assets/img/illustrations/crm-line-chart.png" alt=""
                width="150" />
            </div>

            <div class="col-md-auto p-1">
              <form class="row align-items-center g-3">
                <div class="col-auto">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-falcon-primary nav-link nav-link-card-details py-3 border dropdown-toggle px-2"
                      type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      style="width: 150px">
                      <i class="fa fa-home text-warning icon-spin"></i>

                      {{ order_type.name || "Location?" }}
                    </button>

                    <div class="dropdown-menu">
                      <a class="dropdown-item nav-item me-2 me-lg-0 d-block" v-for="(item, i) in order_types" :key="i"
                        @click="selectOrderType(item)">
                        <a class="nav-link nav-link-card-details py-3 px-2 border text-center" role="button"
                          :class="item.id == order_type.id ? `bg-success text-white` : ``">{{ item.name }}
                        </a>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-auto">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-falcon-primary nav-link nav-link-card-details py-3 border dropdown-toggle px-2"
                      type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      style="width: 150px">
                      <i class="fa-solid fa-folder-open text-warning icon-spin"></i>

                      {{ category.name || "Category?" }}
                    </button>

                    <div class="dropdown-menu scrollbar" style="max-height: 600%">
                      <a class="dropdown-item nav-item me-2 me-lg-0 d-block" v-for="(item, i) in productCategories"
                        :key="i" @click="categorySelected(item)">
                        <a class="nav-link nav-link-card-details py-3 px-2 border text-center" role="button" :class="item.id == selected_category ? `bg-success text-white` : ``
                          ">{{ item.name }}
                        </a>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- Group  -->

                <div class="col-auto">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-falcon-primary nav-link nav-link-card-details py-3 border dropdown-toggle px-2"
                      type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      style="width: 150px">
                      <i class="fa-solid fa-list text-warning icon-spin"></i>

                      {{ selected_group.name || "Group?" }}
                    </button>

                    <div class="dropdown-menu scrollbar" style="max-height: 600%">
                      <a class="dropdown-item nav-item me-2 me-lg-0 d-block" v-for="(item, i) in groups" :key="i"
                        @click="selectGroup(item)">
                        <a class="nav-link nav-link-card-details py-3 px-2 border text-center" role="button" :class="item.id == selected_group ? `bg-success text-white` : ``
                          ">{{ item.name }}
                        </a>
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
      <div class="alert alert-primary my-2" role="alert">
          Your customers can now pay you directly via M-Pesa Paybill No. <span class="fw-bold fs-bold font-xl">4114541</span> and
          Account No. <span class="fw-bold fs-bold font-xl">{{ user.company.unique_id }}</span>. We're working to
          expand our payment options around Africa. Stay tuned for more updates!
      </div>
    </div> -->
    <div class="row g-2 ps-0">
      <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card mb-2">
          <div class="p-2">
            <div class="row flex-between-center">
              <div class="col-sm-auto mb-2 mb-sm-0 h-1">
                <h6 class="mb-0">Showing {{ items.length }} Products</h6>
              </div>

              <div class="col-auto d-flex align-items-center">
                <button @click="toggleRetail" class="btn btn-sm"
                  :class="formData.retail_price ? 'btn-falcon-warning' : 'btn-warning'">
                  Wholesale
                </button>

                <button @click="toggleRetail" class="btn mx-2 btn-sm"
                  :class="formData.retail_price ? 'btn-warning' : 'btn-falcon-warning'">
                  Retail
                </button>
              </div>
            </div>
          </div>

          <div class="p-0" v-if="user && open_orders_count">
            <ul class="mb-0 list-unstyled">
              <li class="alert mb-0 rounded-0 py-2 px-card alert-info border-x-0 border-top-0">
                <div class="row flex-between-center">
                  <div class="col">
                    <div class="d-flex">
                      <div class="fas fa-circle mt-1 fs--2"></div>
                      <p class="fs--1 ps-2 mb-0">
                        <strong>{{ open_orders_count }} order(s)</strong> are waiting your
                        action!
                      </p>
                    </div>
                  </div>

                  <div class="col-auto d-flex align-items-center">
                    <a class="alert-link fs--1 fw-medium" @click="goToAllOrders" role="button">View orders<i
                        class="fas fa-chevron-right ms-1 fs--2"></i></a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- Edn goups  -->

        <div class="card">
          <div class="card-header">
            <div class="row flex-between-center border-bottom">
              <div class="col-4 col-sm-auto align-items-center pe-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  <i class="fa fa-shopping-cart text-warning"></i> Menu Items
                </h5>

                <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                  Search items by name or barcode
                </p>
              </div>

              <div class="col-12 col-sm-auto ms-auto text-end ps-0 d-flex mb-1">
                <button class="btn btn-sm me-2" :class="list_view ? 'btn-falcon-warning' : 'btn-warning'"
                  @click="toggleView">
                  <i class="fa fa-table"></i>
                </button>

                <div class="input-group shadow">
                  <input ref="search_input" class="form-control search ms-auto fw-bold fs-bold border" type="search"
                    placeholder="Search..." aria-label="search" width="100%" v-model="search_term"
                    v-on:keyup.enter="searchItemOrBarcode" />

                  <div class="input-group-text bg-transparent">
                    <span class="fa fa-search fs--1 text-600"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body pt-0">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                <div v-if="!order_type_selection">
                  <div v-if="list_view" class="row">
                    <div v-if="items.length > 0" class="table-responsive scrollbar-overlay" style="max-height: 30rem">
                      <table class="table table-bordered table-striped">
                        <thead class="bg-light fw-bold text-primary text-900">
                          <tr>
                            <th class="sort" data-sort="photo" width="10%">Photo</th>

                            <th class="sort" data-sort="name" width="70%">Name</th>

                            <th class="sort text-end" data-sort="price" width="20%">
                              Price (Ksh)
                            </th>
                          </tr>
                        </thead>

                        <tbody class="">
                          <tr v-for="(item, i) in items" :key="i" @click="addTocart(item)" role="button">
                            <td>
                              <div class="avatar avatar-xl">
                                <img v-if="item.image" class="rounded-circle" :src="item.image" alt="Product Image"
                                  height="32" />

                                <img v-else class="rounded-circle" src="assets/img/no-image.jpg" alt="Product Image"
                                  height="32" />
                              </div>
                            </td>

                            <td>
                              <div class="my-0">
                                <strong>{{ item.name.toUpperCase() }}</strong>

                                <p class="mb-0 text-500 text-wrap">
                                  {{ item.email }}
                                </p>
                              </div>
                            </td>

                            <td v-if="user.company.show_item_buying_price">
                              {{ item.buy_price }}
                            </td>

                            <td class="text-end fw-bold">
                              {{
                                this.formData.retail_price
                                ? formatToCurrency(item.price)
                                : formatToCurrency(item.ws_price)
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <p v-else>No item found!</p>
                  </div>

                  <div v-if="!list_view" class="row mx-1">
                    <div v-if="items.length > 0"
                      class="col-6 col-md-4 col-lg-4 border rounded-1 d-flex flex-column justify-content-between scrollbar-overlay"
                      style="max-height: 30rem" v-for="(item, i) in items" :key="i" @click="addTocart(item)">
                      <span v-if="user.company.show_item_buying_price" class="btn btn-sm btn-falcon-secondary mt-1">B.P {{
                        item.buy_price }}</span>

                      <div class="overflow-hidden">
                        <div class="position-relative rounded-top overflow-hidden">
                          <a class="d-block text-center" role="button">
                            <img v-if="item.image" class="rounded-3 mt-1 img-flui" :src="item.image" alt="Product Image"
                              height="60" />

                            <img v-else class="rounded-3 mt-3 img-flui" src="assets/img/no-image.jpg" alt="Product Image"
                              height="60" />
                          </a>
                        </div>

                        <div class="text-center mt-2">
                          <span v-if="user.company.show_item_quantity" class="badge rounded-pill" :class="item.stock_amount > item.reorder_level
                            ? 'badge-soft-info'
                            : 'badge-soft-danger'
                            ">Qty: {{ item.stock_amount }}</span>

                          <h5 class="fs--1">
                            <a class="text-dark fw-bold" role="button">
                              {{ truncateText(item.name, 15).toUpperCase() }}
                            </a>
                          </h5>

                          <span class="fs--3 text-warning d-block" role="button">
                            <small class="me-1">KES</small>
                            <b>{{ formatToCurrency(item.price) }}</b>
                          </span>
                        </div>
                      </div>
                    </div>

                    <p v-else>No item found!</p>
                  </div>

                  <div class="d-fle justify-content-left mt-3">
                    <pagination v-model="current_page" :records="records" :per-page="per_page" @paginate="myCallback" />
                  </div>
                </div>

                <!-- Order type selection  -->

                <div v-if="order_type_selection" class="row g-2">
                  <div v-if="order_types.length > 0"
                    class="btn btn-falcon-warning col-6 col-md-4 col-lg-4 border rounded-2 d-flex flex-column justify-content-between pt-3"
                    v-for="(item, i) in order_types" :key="i" @click="selectOrderType(item)">
                    <div class="overflow-hidden">
                      <div class="position-relative rounded-top overflow-hidden">
                        <p class="d-block text-center" role="button">
                          <i v-if="item.icon" :class="item.icon" class="fa-3x"></i>

                          <i v-else class="fa fa-table fa-3x"></i>
                        </p>
                      </div>

                      <div class="text-center mt-2">
                        <h5 class="fs-1">
                          <p class="text-primary fw-bold" role="button">
                            {{ truncateText(item.name, 15).toUpperCase() }}
                          </p>
                        </h5>
                      </div>
                    </div>
                  </div>

                  <p v-else>No item found!</p>

                  <img :src="user.company.logo" />
                </div>
              </div>

              <div class="col-12 col-md-6 col-mlg-6 col-xl-6">
                <div class="card border-right scrollbar list" style="max-height: 100%">
                  <div class="card-header bg-light d-flex">
                    <h6 class="mb-md-0">Cart ({{ cart.length || "No" }} Items)</h6>
                    <span class="text-primary ms-auto">{{ formData.customer_name }}</span>
                  </div>

                  <div class="card-body">
                    <div class="row mb-2">
                      <div class="col-5 d-flex">
                        <!-- <span class="text-sm-end me-2 h4 p-2">Total</span> -->
                        <div class="alert alert-success fw-bold p-2 h5">
                          KSH. {{ formatToCurrency(totalValue) }}
                        </div>
                      </div>

                      <div class="col-7 d-flex">
                        <Select2 class="d-block border" style="width: 80%; max-height: 38px !important;"
                          placeholder="Select customer..." v-model="formData.customer_id" :options="customers"
                          @select="mySelectEvent($event)" />

                        <button @click="clearCart" style="max-height: 38px !important;"
                          class="btn btn-sm btn-falcon-danger">
                          <i class="fa fa-cart-arrow-down"></i>
                        </button>
                      </div>
                    </div>

                    <div class="table-responsive scrollbar-overlay" style="max-height: 15rem" data-simplebar="init">
                      <table class="table table-bordered table-striped">
                        <thead>
                          <tr class="bg-light fw-bold text-primary">
                            <th class="fs--1" scope="col" style="width: 50%">Name</th>

                            <th class="text-center fs--1" style="width: 10%">Qty</th>

                            <!-- <th scope="col">Price</th> -->

                            <th class="text-end fs--1" style="width: 30%">Total</th>

                            <th class="text-end fs--1" style="width: 10%">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(item, i) in cartItems" :key="i">
                            <td class="fs--1">
                              {{ truncateText(item.name, 50) }}
                            </td>

                            <td class="fs--1">
                              <div>
                                <div class="input-group input-group-sm flex-nowrap" data-quantity="data-quantity">
                                  <button class="btn btn-sm btn-outline-secondary border-300 px-2 shadow-none"
                                    data-type="minus" @click="editItem(item, item.quantity - 1)">
                                    -
                                  </button>

                                  <input class="form-control text-center px-2 input-spin-none fs--1" type="number" min="1"
                                    v-model="item.quantity" @change="editItemQty(item, $event)"
                                    aria-label="Amount (to the nearest dollar)" style="width: 50px" />

                                  <button class="btn btn-sm btn-outline-secondary border-300 px-2 shadow-none"
                                    data-type="plus" @click="editItem(item, item.quantity + 1)">
                                    +
                                  </button>
                                </div>
                              </div>
                            </td>

                            <td class="text-end fs--1">
                              <!-- Start of price  -->

                              <span v-if="!allow_price_edits" class="fw-bold">{{
                                formatToCurrency(
                                  (formData.retail_price ? item.price : item.ws_price) *
                                  item.quantity
                                )
                              }}</span>

                              <span v-if="allow_price_edits">
                                <input v-if="formData.retail_price" class="form-control"
                                  :value="item.price * item.quantity" @change="editItemPrice($event, item)" />

                                <input v-else class="form-control" :value="item.ws_price * item.quantity"
                                  @change="editItemPrice($event, item)" />
                              </span>

                              <!-- End of price  -->

                              <br />

                              <span class="fs--2 d-block text-primary">{{
                                item.vat
                                ? `VAT:` +
                                formatToCurrency(
                                  getVAT(
                                    (formData.retail_price
                                      ? item.price
                                      : item.ws_price) * item.quantity
                                  )
                                )
                                : ""
                              }}</span>
                            </td>

                            <td class="text-end fs--1">
                              <button class="btn btn-sm btn-falcon-danger fs--1 d-flex" type="button"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" @click="deleteItem(item)">
                                <i class="fas fa-trash-alt pt-1"></i>

                                Del
                              </button>
                            </td>
                          </tr>

                          <tr class="bg-light border-top fs--1">
                            <td class="text-600" colspan="3">Sub Total (Ksh)</td>

                            <td class="text-end">
                              {{ formatToCurrency(totalValue - totalVat) }}
                            </td>
                          </tr>

                          <tr class="bg-light fs--1 py-0">
                            <td class="text-600" colspan="3">Total VAT (16%)</td>

                            <td class="text-end">
                              {{ formatToCurrency(totalVat) }}
                            </td>
                          </tr>

                          <tr class="bg-light fs--1">
                            <td class="text-900 fw-bold" colspan="3">TOTAL (Ksh)</td>

                            <td class="fs--3 fw-bold text-end">
                              {{ formatToCurrency(totalValue) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="card-footer mt-n3 border-top">
                    <div class="row">
                      <div class="col-6 d-flex mb-1">
                        <input class="form-control me-1 primary text-primary fw-bold" placeholder="Amount"
                          v-model="tender_amt" />
                        <input class="form-control primary text-success fw-bold" disabled placeholder="Balance"
                          v-model="balance" />
                      </div>
                      <div class="col-6 d-flex">
                        <div class="ms-auto form-check form-switch">
                          <input class="me-2 form-check-input" type="checkbox" id="flexSwitchCheckPrimary"
                            :checked="print_receipt" v-model="print_receipt" /><label class="form-check-label mb-0"
                            for="flexSwitchCheckDefault">Print receipt</label>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex">
                      <button v-if="company.show_order_on_pos" @click="orderTicket"
                        class="btn btn-falcon-info py-1 m-1 col">
                        <i class="fa fa-cart-plus me-2 mt-1"></i><br />Order
                      </button>

                      <button v-if="company.show_mpesa_on_pos" @click="payMpesa"
                        class="btn btn-falcon-success py-1 m-1 col">
                        <i class="fa fa-credit-card me-2 mt-1"></i><br />MPESA
                      </button>

                      <button v-if="company.show_cash_on_pos" @click="payCash"
                        class="btn btn-falcon-primary py-1 m-1 col">
                        <i class="fa fa-check-square me-2 mt-1"></i><br />CASH
                      </button>

                      <button v-if="company.show_mpesa_on_pos" @click="payCard"
                        class="btn btn-falcon-info py-1 m-1 col">
                        <i class="fa fa-check-square me-2 mt-1"></i><br />CARD
                      </button>
                    </div>
                    <!-- Footer  -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer bg-light d-flex justify-content-center">
            <!-- <div>Bulla</div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="authentication-modal" tabindex="-1" role="dialog"
      aria-labelledby="authentication-modal-label" aria-hidden="true">
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div class="modal-header px-5 position-relative modal-shape-header bg-shape">
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">Register</h4>

              <p class="fs--1 mb-0 text-white">Please create your free Falcon account</p>
            </div>

            <button class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>

          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label><input class="form-control" type="text"
                  autocomplete="on" id="modal-auth-name" />
              </div>

              <div class="mb-3">
                <label class="form-label" for="modal-auth-email">Email address</label><input class="form-control"
                  type="email" autocomplete="on" id="modal-auth-email" />
              </div>

              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password">Password</label><input class="form-control"
                    type="password" autocomplete="on" id="modal-auth-password" />
                </div>

                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password">Confirm Password</label><input
                    class="form-control" type="password" autocomplete="on" id="modal-auth-confirm-password" />
                </div>
              </div>

              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="modal-auth-register-checkbox" /><label
                  class="form-label" for="modal-auth-register-checkbox">I accept the <a href="index.html#!">terms </a>and

                  <a href="index.html#!">privacy policy</a></label>
              </div>

              <div class="mb-3">
                <button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">
                  Register
                </button>
              </div>
            </form>

            <div class="position-relative mt-5">
              <hr />

              <div class="divider-content-center">or register with</div>
            </div>

            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a class="btn btn-outline-google-plus btn-sm d-block w-100" href="index.html#"><span
                    class="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span>

                  google</a>
              </div>

              <div class="col-sm-6">
                <a class="btn btn-outline-facebook btn-sm d-block w-100" href="index.html#"><span
                    class="fab fa-facebook-square me-2" data-fa-transform="grow-8"></span>

                  facebook</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-body-row-height: 60px;
  --easy-table-body-row-font-size: 12px;
}

.page-link {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
</style>

<script>
import axios from "axios";
import CountUp from "vue-countup-v3";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Pagination from "v-pagination-3";
import CartService from "../services/cart.service";
import AuthService from "../services/auth.service";
import TablesService from "../services/tables.service";
import TicketService from "../services/ticket.service";
import ProductService from "../services/product.service";
import MenuItemService from "../services/menu_item.service";
import MenuGroupService from "../services/menu_group.service";
import MenuCategoryService from "../services/menu_category.service";
import CustomerService from "../services/customer.service";
import OrderTypeService from "../services/order_types.service";
import useVuelidate from "@vuelidate/core";
import OrderService from "../services/orders.service";
import { required } from "@vuelidate/validators";
import OrdersService from "../services/orders.service";
import NotificationService from "../services/notification.service";

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        phone: { required },
        price: { required },
      },
    };
  },
  components: {
    CountUp,
    Pagination,
  },
  data() {
    return {
      can_retry: true,
      retrying: false,
      errorMessage: false,
      successMessage: false,
      stk_sent: false,
      transaction_complete: false,
      tender_amt: "",
      cart_balance: "",
      print_receipt: true,
      open_orders_count: 0,
      search_term: "",
      list_view: true,
      per_page: 25,
      current_page: 0,
      records: 0,
      checkout: false,
      daily_analysis: [],
      weekly_analysis: [],
      formData: {
        group_name: "",
        category: "",
        category_name: "",
        retail_price: true,
        table_id: 1,
        customer_id: "",
        order_type_id: "",
        order_type_name: "",
        cart: {},
        total_amount: 0,
      },
      searchKey: "",
      barcode: "",
      loading_qr: false,
      dialog: false,
      items: {},
      tables: [],
      order_types: [],
      dialogQty: false,
      itemDialog: false,
      productCategories: [],
      customers: [],
      groups: [],
      selectedProductCategory: "",
      cart: {},
      table_selection: localStorage.getItem("table") == null ? true : false,
      category_selection: false,
      order_type_selection: true,
      order_type:
        localStorage.getItem("order_type") == null
          ? {}
          : JSON.parse(localStorage.getItem("order_type")),
      selectedCategory: 0,
      selected_category: "",
      selected_category_data: {},
      selected_group: "",
      selected_table: "",
      selected_customer: "",
    };
  },
  mounted() {
    this.$refs.search_input.focus();
  },
  async created() {
    let status = await AuthService.checkAuth();
    if (status == 401) {
      localStorage.removeItem("user");
      alert("Session expired");
      this.$router.push("/login");
    }
    this.getAllOrderTypes();
    this.getAllCategories();
    this.getAllGroups();
    this.getOpenOrdersCount();
    // this.getAllTables();
    this.getCustomers();
  },
  computed: {
    balance() {
      if (this.tender_amt) {
        return this.tender_amt - this.totalValue;
      } else {
        return ""
      }
    },
    totalValue() {
      let itemArray = Object.values(this.cart);
      let totalValue = 0;
      itemArray.forEach((element) => {
        totalValue +=
          parseFloat(element.quantity) *
          parseFloat(this.formData.retail_price ? element.price : element.ws_price);
      });
      return parseFloat(totalValue);
    },
    totalVat() {
      let itemArray = Object.values(this.cart);
      let totalValue = 0;
      itemArray.forEach((element) => {
        if (element.vat) {
          totalValue +=
            parseFloat(element.quantity) *
            parseFloat(
              this.formData.retail_price
                ? this.getVAT(element.price)
                : this.getVAT(element.ws_price)
            );
        }
      });
      return totalValue;
    },
    cartLength() {
      let data = this.cart;
      if (data.length) {
        return data.length;
      } else {
        return "empty";
      }
    },
    cartItems() {
      let cartData = Object.values(this.cart);
      return cartData;
    },
    table() {
      return localStorage.getItem("table") == null ? {} : this.selected_table;
    },
    category() {
      return this.selected_category_data;
    },
    company() {
      return JSON.parse(localStorage.getItem("user")).company;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    allow_price_edits() {
      let user = JSON.parse(localStorage.getItem("user"));
      return user ? user.company.allow_price_edits : false;
    },
  },
  methods: {
    
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async checkStk() {
      this.stk_sent = true;
      this.$store.commit("SET_LOADING", true);
      let user = JSON.parse(localStorage.getItem("user"));
      var params2 = new FormData();
      params2.append("CheckoutRequestID", this.CheckoutRequestID);
      params2.append("BillRefNumber", user.company.unique_id);
      params2.append("TransAmount", this.formData.price);

      for (let i = 0; i < 5; i++) {
        if (this.transaction_complete) {
          this.stk_sent = false;
          this.$store.commit("SET_LOADING", false);
          this.can_retry = true;
          if (!this.errorMessage) {
            this.successMessage = true;
          }
          break;
        } else {
          await axios
            .post("https://api.bulla.africa/stk-query.php", params2)
            .then((response) => {
              if (response.data.ResponseCode == 0 && response.data.ResultCode == 0) {
                toast.success(response.data.ResultDesc);
                console.log("Payment complete..");
                this.transaction_complete = true;
                this.$store.commit("SET_LOADING", false);
              } else if (
                typeof response.data.errorCode != undefined &&
                response.data.errorMessage == "The transaction is being processed"
              ) {
                toast.info(response.data.errorMessage);
              }
              // Insufficient bal
              else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultDesc ==
                "The balance is insufficient for the transaction."
              ) {
                toast.error(response.data.ResultDesc);
                this.errorMessage = response.data.ResultDesc;
                this.$store.commit("SET_LOADING", false);
              }
              // DS not reached
              else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultCode == 1037
              ) {
                toast.error(response.data.ResultDesc);
                this.transaction_complete = true;
                this.errorMessage = response.data.ResultDesc;
                this.$store.commit("SET_LOADING", false);
              }
              // DS cancelled
              else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultCode == 1032
              ) {
                toast.error(response.data.ResultDesc);
                this.transaction_complete = true;
                this.errorMessage = response.data.ResultDesc;
                this.$store.commit("SET_LOADING", false);
              } else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultCode == 2001
              ) {
                this.transaction_complete = true;
                this.$store.commit("SET_LOADING", false);
              } else {
                toast.info(response.data.ResultDesc);
              }
            });
          await this.sleep(10000);
        }
      }
    },
    async handlePay() {
      this.v$.formData.$touch();
      if (this.v$.formData.$invalid) {
        return;
      } else {
        this.$store.commit("SET_LOADING", true);
        this.retrying = true;
        this.can_retry = false;
        this.errorMessage = false;
        this.successMessage = false;
        this.transaction_complete = false;
        let formData = new FormData();
        // formData.append("amount", this.selectedPlan.price);
        let user = JSON.parse(localStorage.getItem("user"));
        formData.append("amount", this.formData.price);
        formData.append("phone_number", "254" + parseInt(this.formData.phone.replace(/\+/g, "")));
        formData.append("account_reference", user.company.unique_id);
        this.pushSTK(formData);
      }
    },
    async pushSTK(params) {
      // format phone
      this.$store.commit("SET_LOADING", true);
      await axios
        .post("https://api.bulla.africa/stk-push.php", params)
        .then((response) => {
          console.log(response.data);
          if (response.data.errorMessage || response.data.errorCode) {
            toast.error(response.data.errorMessage);
            this.stk_sent = false;
            this.can_retry = true;
            this.$store.commit("SET_LOADING", false);
          } else if (response.data.ResultDesc == "The balance is insufficient for the transaction."
          ) {
            toast.error(response.data.ResultDesc);
            this.stk_sent = false;
            this.can_retry = true;
            this.$store.commit("SET_LOADING", false);
          } else if (response.data.ResultDesc == "Rule limited."
          ) {
            toast.error(response.data.ResultDesc);
            this.stk_sent = false;
            this.can_retry = true;
            this.$store.commit("SET_LOADING", false);
          }
          else {
            this.CheckoutRequestID = response.data.CheckoutRequestID;
            toast.success("PIN prompt sent to your phone!");
            this.can_retry = true;
            this.checkStk();
          }
        });
    },
    printReceipt() {
      OrderService.printReceiptSuper(this.formData).then(
        (response) => {
          toast.success("Receipt printed!", {
            autoClose: 2000,
          });
          console.log(response);
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          toast.error(
            error.response.data.message ||
            (error.response && error.response.data) ||
            error.mesage ||
            error.toString(),
            { autoClose: 3000 }
          );
          console.log(error);
        }
      );
    },
    getOpenOrdersCount() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.openCount().then(
        (response) => {
          if (response.data.code == 200) {
            this.open_orders_count = response.data.data.count;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    beepSound() {
      var audio = new Audio("/error_sound.wav"); // path to file
      audio.play();
    },
    editItemQty(item, evt) {
      this.editItem(item, evt.target.value);
      console.log(evt.target.value);
    },
    getNotifications() {
      return NotificationService.latest().then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_NOTIFICATIONS", response.data.data);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
      this.formData.customer_id = id;
      this.formData.customer_name = text;
    },
    editItemPrice(e, item) {
      console.log("price", e.target.value);
      console.log("item", item);
      let newPrice = e.target.value;
      this.updateSingleItemPrice(item, newPrice);
    },
    updateSingleItemPrice(item, newPrice) {
      console.log("newPrice", newPrice);
      let myCart = this.cart ? Object.values(this.cart) : [];
      let existingElement = myCart.find((element) => element.id == item.id);
      item.sub_total = newPrice;
      existingElement.price = newPrice / item.quantity;
      existingElement.sub_total = newPrice;
      console.log("myCart", myCart);
      this.cart = myCart;
      this.$store.dispatch("pos/updateCart", this.cart);
    },

    toggleRetail() {
      this.formData.retail_price = !this.formData.retail_price;
    },
    toggleView() {
      this.list_view = !this.list_view;
    },
    selectGroup(e) {
      this.selected_group = e.id;
      this.formData.group_name = e.name;
      let formData = {
        menu_group_id: e.id,
      };
      this.menuList(formData, 1);
    },
    async searchItemOrBarcode() {
      if (!isNaN(this.search_term)) {
        console.log("searchItemByBarcode");
        await this.searchItemByBarcode();
        this.$store.commit("SET_LOADING", false);
      } else {
        await this.searchItem();
        this.$store.commit("SET_LOADING", false);
      }
    },
    searchItemByBarcode() {
      this.$store.commit("SET_LOADING", true);
      let formData = {
        search_term: this.search_term,
      };
      return MenuItemService.menuSearchByCode(formData).then(
        (response) => {
          if (response.data.code == 200) {
            let item = response.data.data;
            // add to cart by barcode
            this.addTocart(item);
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
            this.$store.commit("SET_LOADING", false);
          }
          this.search_term = "";
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          this.beepSound();
          toast.error("Item not found, please search!");
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.search_term = "";
        }
      );
    },
    searchItem() {
      this.$store.commit("SET_LOADING", true);
      this.page = 1;
      this.per_page = 25;
      this.current_page = 0;
      let formData = {
        search_term: this.search_term,
      };
      return MenuItemService.menuSearch(formData, this.current_page).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.records = response.data.data.total;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteItem(item) {
      this.cart.splice(this.cart.indexOf(item), 1);
    },
    editItem(item, quantity) {
      if (quantity == 0) {
        this.cart.splice(this.cart.indexOf(item), 1);
      }
      let myCart = this.cart ? Object.values(this.cart) : [];
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.id == item.id);
        existingElement.quantity = quantity;
        existingElement.sub_total = quantity * item.price;
      }
      this.cart = myCart;
      toast.success("1 " + item.name + " updated in cart");
    },
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    toggleCheckout(val) {
      this.checkout = val;
    },
    formatToCurrency: function (amount) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    sortItems(list) {
      return list.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    tenderedInput() {
      this.formData.balance_amount =
        parseFloat(this.formData.tender_amount) - parseFloat(this.totalValue);
    },
    payMpesa() {
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "MPESA";
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.getNotifications();
            this.cart = {};
            // Print ticket
            let company = JSON.parse(localStorage.getItem("user")).company;
            if (company.type == "supermarket" || company.type == "pharmacy") {
              // Print receipt
              this.formData.ticket_items = JSON.stringify(
                this.formData.cart.map((el) => {
                  return {
                    name: el.name,
                    price: this.formatToCurrency(
                      this.formData.retail_price ? el.price : el.ws_price
                    ),
                    quantity: el.quantity,
                    subtotal: this.formatToCurrency(
                      (this.formData.retail_price ? el.price : el.ws_price) * el.quantity
                    ),
                    vat: el.vat
                      ? this.formatToCurrency(
                        this.getVAT(
                          (this.formData.retail_price ? el.price : el.ws_price) *
                          el.quantity
                        )
                      )
                      : 0,
                  };
                })
              );
              this.$store.commit("SET_LOADING", true);
              this.editedItem = response.data.data;
              this.formData.os = "windows";
              this.formData.restaurant_name = this.user.company.name;
              this.formData.date = this.formatDate(this.editedItem.create_date);
              this.formData.reference = this.createReference(
                this.editedItem.create_date,
                this.editedItem.id,
                "BL"
              );
              this.formData.created_by = this.user.first_name;
              this.formData.address_line_1 = this.user.company.address_line1;
              this.formData.address_line_2 = this.user.company.address_line2;
              this.formData.address_line_3 = this.user.company.address_line3;
              this.formData.footer = this.user.company.ticket_footer;
              this.formData.sub_total = this.formatToCurrency(
                this.editedItem.total_price - this.getVAT(this.editedItem.total_price)
              );
              this.formData.tax = this.editedItem.total_tax;
              this.formData.total_amount = this.formatToCurrency(
                this.editedItem.total_price
              );
              this.formData.printer_ip = "127.0.0.1";
              this.formData.vat = this.totalVat;
              this.$store.commit("SET_LOADING", false);
              if (this.print_receipt) {
                console.log("RECeIPT:::", this.print_receipt)
                this.printReceipt();
              }
            }
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    payCash() {
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "CASH";
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.getNotifications();
            this.cart = {};
            // Print ticket
            let company = JSON.parse(localStorage.getItem("user")).company;
            if (company.type == "supermarket") {
              // Print receipt
              this.formData.ticket_items = JSON.stringify(
                this.formData.cart.map((el) => {
                  return {
                    name: el.name,
                    price: this.formatToCurrency(
                      this.formData.retail_price ? el.price : el.ws_price
                    ),
                    quantity: el.quantity,
                    subtotal: this.formatToCurrency(
                      (this.formData.retail_price ? el.price : el.ws_price) * el.quantity
                    ),
                    vat: el.vat
                      ? this.formatToCurrency(
                        this.getVAT(
                          (this.formData.retail_price ? el.price : el.ws_price) *
                          el.quantity
                        )
                      )
                      : 0,
                  };
                })
              );
              this.$store.commit("SET_LOADING", true);
              this.editedItem = response.data.data;
              this.formData.os = "windows";
              this.formData.restaurant_name = this.user.company.name;
              this.formData.date = this.formatDate(this.editedItem.create_date);
              this.formData.reference = this.createReference(
                this.editedItem.create_date,
                this.editedItem.id,
                "BL"
              );
              this.formData.created_by = this.user.first_name;
              this.formData.address_line_1 = this.user.company.address_line1;
              this.formData.address_line_2 = this.user.company.address_line2;
              this.formData.address_line_3 = this.user.company.address_line3;
              this.formData.footer = this.user.company.ticket_footer;
              this.formData.sub_total = this.formatToCurrency(
                this.editedItem.total_price - this.getVAT(this.editedItem.total_price)
              );
              this.formData.tax = this.editedItem.total_tax;
              this.formData.total_amount = this.formatToCurrency(
                this.editedItem.total_price
              );
              this.formData.printer_ip = "127.0.0.1";
              this.formData.vat = this.totalVat;
              this.$store.commit("SET_LOADING", false);
              if (this.print_receipt) {
                console.log("RECeIPT:::", this.print_receipt)
                this.printReceipt();
              }
            }
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    payCard() {
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "CARD";
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.getNotifications();
            this.cart = {};
            // Print ticket
            let company = JSON.parse(localStorage.getItem("user")).company;
            if (company.type == "supermarket") {
              // Print receipt
              this.formData.ticket_items = JSON.stringify(
                this.formData.cart.map((el) => {
                  return {
                    name: el.name,
                    price: this.formatToCurrency(
                      this.formData.retail_price ? el.price : el.ws_price
                    ),
                    quantity: el.quantity,
                    subtotal: this.formatToCurrency(
                      (this.formData.retail_price ? el.price : el.ws_price) * el.quantity
                    ),
                    vat: el.vat
                      ? this.formatToCurrency(
                        this.getVAT(
                          (this.formData.retail_price ? el.price : el.ws_price) *
                          el.quantity
                        )
                      )
                      : 0,
                  };
                })
              );
              this.$store.commit("SET_LOADING", true);
              this.editedItem = response.data.data;
              this.formData.os = "windows";
              this.formData.restaurant_name = this.user.company.name;
              this.formData.date = this.formatDate(this.editedItem.create_date);
              this.formData.reference = this.createReference(
                this.editedItem.create_date,
                this.editedItem.id,
                "BL"
              );
              this.formData.created_by = this.user.first_name;
              this.formData.address_line_1 = this.user.company.address_line1;
              this.formData.address_line_2 = this.user.company.address_line2;
              this.formData.address_line_3 = this.user.company.address_line3;
              this.formData.footer = this.user.company.ticket_footer;
              this.formData.sub_total = this.formatToCurrency(
                this.editedItem.total_price - this.getVAT(this.editedItem.total_price)
              );
              this.formData.tax = this.editedItem.total_tax;
              this.formData.total_amount = this.formatToCurrency(
                this.editedItem.total_price
              );
              this.formData.printer_ip = "127.0.0.1";
              this.formData.vat = this.totalVat;
              this.$store.commit("SET_LOADING", false);
              if (this.print_receipt) {
                console.log("RECeIPT:::", this.print_receipt)
                this.printReceipt();
              }
            }
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    completeCheckout() {
      this.preview_cash_modal = false;
      this.preview_dc_modal = false;
      this.digital_change = false;
      this.change_sent = false;
      this.qrData = {};
      this.clearCart();
      this.$notify({
        group: "foo",
        type: "success",
        title: "Checkout complete",
        text: "order places and paid successfuly!",
      });
    },
    async categorySelected(cat) {
      this.selected_category_data = cat;
      this.selected_category = cat.id;
      this.formData.category_name = cat.name;
      this.formData.category = cat.id;
      this.getMenuGroupsByCategory(cat.id);
    },
    getMenuGroupsByCategory(selected_category) {
      let formData = {
        menu_category: selected_category,
      };
      return ProductService.getMenuGroupsByCategory(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
            if (response.data.data.length > 0) {
              let formData = {
                menu_group_id: this.groups.at(0).id,
              };
              this.menuList(formData, 1);
            }
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    orderTicket() {
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Order placed!");
            this.getNotifications();
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
      this.open_orders_count += 1;
    },
    clearCart() {
      this.cart = {};
      toast.info("Cart cleared!");
    },
    goToMyOrders() {
      this.$router.push("/my-orders");
    },
    goToAllOrders() {
      this.$router.push("/pos-open-order");
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    menuList(formData, page) {
      this.$store.commit("SET_LOADING", true);
      return MenuItemService.menuList(formData, page).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getItemsByOrder(item) {
      this.$store.commit("SET_LOADING", true);
      return CartService.byOrder(item.id).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    myCallback(e) {
      let formData = {};
      if (this.selected_group) {
        formData.menu_group_id = this.selected_group;
      }
      if (this.order_type.id) {
        formData.order_type_id = this.order_type.id;
      }
      if (this.search_term) {
        this.searchItem();
      } else {
        this.menuList(formData, e);
      }
    },
    getAll() {
      return CartService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.current_page = response.data.data.current_page;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllTables() {
      this.$store.commit("SET_LOADING", true);
      return TablesService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.tables = response.data.data;
            if (this.tables.length > 0) this.selected_table = this.tables.at(0);
            this.$store.commit("SET_LOADING", falses);
          } else {
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllGroups() {
      this.$store.commit("SET_LOADING", true);
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.groups = response.data.data;
            this.formData.group_name = response.data.data.at(0).name;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllCategories() {
      this.$store.commit("SET_LOADING", true);
      return MenuCategoryService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.productCategories = response.data.data;
            this.formData.category = response.data.data.at(0).id;
            this.formData.category_name = response.data.data.at(0).name;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuGroupsByOrder() {
      let formData = {
        order_type: this.order_type.id,
      };
      return ProductService.getMenuGroupsByOrder(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
            this.selected_group = response.data.data.at(0).id;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getCustomers() {
      return CustomerService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.formData.customer_id = response.data.data.at(0).id;
            this.customers = response.data.data.map((el) => {
              return {
                id: el.id,
                text: el.first_name,
              };
            });
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllProducts() {
      return ProductService.getAll().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuCategoryByOrder(order_type) {
      if (this.order_types.length > 0) {
        this.formData.order_type = order_type;
        return ProductService.getMenuCategoryByOrder(this.formData).then(
          (response) => {
            if (response.data.code == 200) {
              this.productCategories = response.data.data;
            } else {
              console.log(response.data.error);
            }
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      } else {
        this.productCategories = [];
      }
    },
    addTocart(item) {
      let myCart = this.cart ? Object.values(this.cart) : [];
      let current_price = this.formData.retail_price ? item.price : item.ws_price;
      if (myCart.find((value) => value.id == item.id)) {
        let existingElement = myCart.find((element) => element.id == item.id);
        item.sub_total = item.quantity * current_price;
        existingElement.quantity = existingElement.quantity + 1;
        existingElement.sub_total =
          existingElement.sub_total + item.quantity * current_price;
      } else {
        item.quantity = 1;
        item.buying_price = item.buy_price;
        item.sub_total = item.quantity * current_price;
        myCart.push(item);
      }
      this.cart = myCart;
      this.$store.dispatch("pos/updateCart", this.cart);
      this.$store.commit("SET_LOADING", false);
    },
    selectTable(item) {
      this.table_selection = false;
      localStorage.setItem("table", JSON.stringify(item));
      this.$notify({
        group: "foo",
        type: "info",
        title: "Selection",
        text: "Table selected!",
      });
    },
    selectOrderType(item) {
      this.order_type = item;
      this.table_selection = true;
      this.order_type_selection = false;
      localStorage.setItem("order_type", JSON.stringify(item));
      let formData = {
        order_type_id: item.id,
      };
      this.menuList(formData, 1);
      this.getMenuCategoryByOrder(item.id);
    },
  },
};
</script>
