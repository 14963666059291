<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-shopping-cart text-warning"></i>
              Product Categories
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage all available menu categories
            </p>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div id="orders-actions" class="d-flex">
              <div class="search-box h1">
                <input
                  class="form-control search-input fuzzy-search"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search"
                  v-model="searchValue"
                />
                <span class="fas fa-search search-box-icon"></span>
              </div>

              <button
                class="btn btn-sm btn-falcon-warning ms-2 h2"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add-modal"
                @click="addNew"
              >
                <i class="fas fa-plus" data-fa-transform=""></i> New
              </button>
              <button
                @click="$router.go()"
                class="btn btn-sm btn-falcon-warning ms-2 h2"
                data-toggle="tooltip"
                data-placement="top"
                title="Refresh page"
              >
                <i class="fa-solid fa-arrows-rotate"></i> Refresh
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <EasyDataTable
          id="tour-step-1"
          table-class-name="customize-tabl"
          alternating
          buttons-pagination
          :headers="headers"
          :items="resources"
          :search-field="searchField"
          :search-value="searchValue"
          :loading="loading"
        >
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-order_types="item">
            <span
              v-for="(item, i) in item.order_types"
              :key="i"
              class="btn badge-soft-primary fs--1 m-1 p-1 d-flex"
              >{{ item.name }}</span
            >
          </template>
          <template #item-name="item">
            <div class="my-2">
              <strong>{{ item.name.toUpperCase() }}</strong>
              <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
            </div>
          </template>
          <template #item-created_at="item">
            <div class="my-2">
              {{ formatDateHuman(item.created_at) }}
            </div>
          </template>
          <template #item-cat_button="item">
            <div class="my-2">
              <button
                v-if="item.btn_color"
                @click="setColor(item)"
                class="badge badge-sm text-white"
                role="button"
                :style="`background-color:${item.btn_color} !important;`"
              >
                {{ item.name }}
              </button>
            </div>
          </template>

          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button
                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="order-dropdown-0"
                data-bs-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-end border py-0"
                aria-labelledby="order-dropdown-0"
              >
                <div class="py-2">
                  <a
                    class="dropdown-item"
                    role="button"
                    @click="editItem(item)"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-modal"
                    >Edit</a
                  >

                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    role="button"
                    @click="deleteRecource(item)"
                    >Delete</a
                  >
                </div>
              </div>
            </div>
          </template>
        </EasyDataTable>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>
    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="edit-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="edit-modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="
                btn-close btn btn-sm btn-circle
                d-flex
                flex-center
                transition-base
              "
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Edit category</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="d-flex">
                  <div class="row">
                    <div class="position-relative">
                      <hr />
                      <div class="divider-content-center">Mandatory fields</div>
                    </div>
                    <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label class="col-form-label" for="recipient-name"
                        >Name</label
                      >
                      <input
                        class="form-control"
                        id="recipient-name"
                        type="text"
                        v-model="formData.name"
                        :class="
                          v$.formData.name.$dirty && v$.formData.name.$invalid
                            ? `is-invalid`
                            : v$.formData.name.$dirty
                            ? `is-valid`
                            : ``
                        "
                      />
                      <div
                        v-for="error of v$.formData.name.$errors"
                        :key="error.$uid"
                        class="invalid-feedback"
                        style="color: red"
                      >
                        This field is invalid
                      </div>
                    </div>
                    <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label class="col-form-label" for="message-text"
                        >Translated name</label
                      >
                      <input
                        class="form-control"
                        id="recipient-name"
                        type="text"
                        v-model="formData.translated_name"
                        :class="
                          v$.formData.translated_name.$dirty &&
                          v$.formData.translated_name.$invalid
                            ? `is-invalid`
                            : v$.formData.translated_name.$dirty
                            ? `is-valid`
                            : ``
                        "
                      />
                      <div
                        v-for="error of v$.formData.translated_name.$errors"
                        :key="error.$uid"
                        class="invalid-feedback"
                        style="color: red"
                      >
                        This field is invalid
                      </div>
                    </div>
                  </div>
                  <hr class="my-2" />
                </div>
                <div class="pt-2">
                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Optional fields</div>
                  </div>
                  <div class="row">
                    <div class="mb-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <label class="col-form-label" for="message-text"
                        >Sort order</label
                      >
                      <input
                        class="form-control"
                        id="recipient-name"
                        type="number"
                        v-model="formData.sort_order"
                      />
                    </div>
                    <div class="mb-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <label class="col-form-label" for="message-text"
                        >Visibility</label
                      >
                      <ul class="nav flex-lg-column fs--1">
                        <li class="nav-item me-2 me-lg-0">
                          <a
                            @click="toggleActive"
                            class="nav-link nav-link-card-details"
                            role="button"
                            :class="
                              formData.visible ? `bg-success text-white` : ``
                            "
                          >
                            <span
                              class="fa me-2"
                              :class="
                                formData.visible == true
                                  ? 'fa-check-square'
                                  : 'fa-close'
                              "
                            ></span
                            >Visible?
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <div class="position-relative">
                  <hr />
                  <div class="divider-content-center">Location</div>
                </div>
                <div class="overflow-auto" style="height: 200px !important">
                  <ul
                    class="nav flex-lg-column fs--1"
                    :key="formData.btn_color"
                  >
                    <li
                      v-for="(item, i) in order_types"
                      :key="i"
                      class="nav-item me-2 me-lg-0"
                    >
                      <a
                        v-if="
                          formData.order_type_ids &&
                          formData.order_type_ids.includes(item.id)
                        "
                        @click="removeFromOrdertype(item.id)"
                        class="
                          bg-success
                          text-white
                          nav-link nav-link-card-details
                        "
                        role="button"
                        ><span class="fa fa-check me-2"></span>
                        {{ item.name }}
                      </a>
                      <a
                        v-else
                        @click="addToOrdertype(item.id)"
                        class="nav-link nav-link-card-details"
                        role="button"
                        ><span class="fa fa-check me-2"></span>
                        {{ item.name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <div class="position-relative">
                  <hr />
                  <div class="divider-content-center">Button Color</div>
                </div>
                <div class="overflow-auto" style="height: 200px !important">
                  <ul
                    class="nav flex-lg-column fs--1"
                    :key="formData.btn_color"
                  >
                    <li
                      class="nav-item me-2 me-lg-0"
                      v-for="(item, i) in colors"
                      :key="i"
                    >
                      <a
                        @click="setColor(item)"
                        class="nav-link nav-link-card-details text-white"
                        role="button"
                        :style="`background-color:${item} !important;`"
                        >{{ item }}<span
                          v-if="item == formData.btn_color"
                          class="fa fa-check ms-4 text-white"
                        ></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="updateResource()"
            >
              <i class="fa-solid fa-square-check"></i>
              <span class="d-none d-sm-inline-block ms-1">Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->

    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="
                btn-close btn btn-sm btn-circle
                d-flex
                flex-center
                transition-base
              "
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">New category</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="d-flex">
                  <div class="row">
                    <div class="position-relative">
                      <hr />
                      <div class="divider-content-center">Mandatory fields</div>
                    </div>
                    <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label class="col-form-label" for="recipient-name"
                        >Name</label
                      >
                      <input
                        class="form-control"
                        id="recipient-name"
                        type="text"
                        v-model="formData.name"
                        :class="
                          v$.formData.name.$dirty && v$.formData.name.$invalid
                            ? `is-invalid`
                            : v$.formData.name.$dirty
                            ? `is-valid`
                            : ``
                        "
                      />
                      <div
                        v-for="error of v$.formData.name.$errors"
                        :key="error.$uid"
                        class="invalid-feedback"
                        style="color: red"
                      >
                        This field is invalid
                      </div>
                    </div>
                    <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label class="col-form-label" for="message-text"
                        >Translated name</label
                      >
                      <input
                        class="form-control"
                        id="recipient-name"
                        type="text"
                        v-model="formData.translated_name"
                        :class="
                          v$.formData.translated_name.$dirty &&
                          v$.formData.translated_name.$invalid
                            ? `is-invalid`
                            : v$.formData.translated_name.$dirty
                            ? `is-valid`
                            : ``
                        "
                      />
                      <div
                        v-for="error of v$.formData.translated_name.$errors"
                        :key="error.$uid"
                        class="invalid-feedback"
                        style="color: red"
                      >
                        This field is invalid
                      </div>
                    </div>
                  </div>
                  <hr class="my-2" />
                </div>
                <div class="pt-2">
                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Optional fields</div>
                  </div>
                  <div class="row">
                    <div class="mb-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <label class="col-form-label" for="message-text"
                        >Sort order</label
                      >
                      <input
                        class="form-control"
                        id="recipient-name"
                        type="number"
                        v-model="formData.sort_order"
                      />
                    </div>
                    <div class="mb-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <label class="col-form-label" for="message-text"
                        >Visibility</label
                      >
                      <ul class="nav flex-lg-column fs--1">
                        <li class="nav-item me-2 me-lg-0">
                          <a
                            @click="toggleActive"
                            class="nav-link nav-link-card-details"
                            role="button"
                            :class="
                              formData.visible ? `bg-success text-white` : ``
                            "
                          >
                            <span
                              class="fa me-2"
                              :class="
                                formData.visible == true
                                  ? 'fa-check-square'
                                  : 'fa-close'
                              "
                            ></span
                            >Visible?
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <div class="position-relative">
                  <hr />
                  <div class="divider-content-center">Location</div>
                </div>
                <div class="overflow-auto" style="height: 200px !important">
                  <ul
                    class="nav flex-lg-column fs--1"
                    :key="formData.btn_color"
                  >
                    <li
                      v-for="(item, i) in order_types"
                      :key="i"
                      class="nav-item me-2 me-lg-0"
                    >
                      <a
                        v-if="
                          formData.order_type_ids &&
                          formData.order_type_ids.includes(item.id)
                        "
                        @click="removeFromOrdertype(item.id)"
                        class="
                          bg-success
                          text-white
                          nav-link nav-link-card-details
                        "
                        role="button"
                        ><span class="fa fa-check me-2"></span>
                        {{ item.name }}
                      </a>
                      <a
                        v-else
                        @click="addToOrdertype(item.id)"
                        class="nav-link nav-link-card-details"
                        role="button"
                        ><span class="fa fa-check me-2"></span>
                        {{ item.name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <div class="position-relative">
                  <hr />
                  <div class="divider-content-center">Button Color</div>
                </div>
                <div class="overflow-auto" style="height: 200px !important">
                  <ul
                    class="nav flex-lg-column fs--1"
                    :key="formData.btn_color"
                  >
                    <li
                      class="nav-item me-2 me-lg-0"
                      v-for="(item, i) in colors"
                      :key="i"
                    >
                      <a
                        @click="setColor(item)"
                        class="nav-link nav-link-card-details text-white"
                        role="button"
                        :style="`background-color:${item} !important;`"
                        >{{ item
                        }}<span
                          v-if="item == formData.btn_color"
                          class="fa fa-check ms-4 text-white"
                        ></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="saveResource()"
            >
              <i class="fa-solid fa-square-check"></i>
              <span class="d-none d-sm-inline-block ms-1">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<style>
.modal-open {
  overflow: scroll;
}
</style>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuCategoryService from "../services/menu_category.service";
import MenuGroupService from "../services/menu_group.service";
import OrderTypeService from "../services/order_types.service";
import MenuItemSizeService from "../services/menu_item_size.service";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        translated_name: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      menu_item_sizes: [],
      groups: [],
      order_types: [],
      searchValue: "",
      table_keys: 1,
      formData: {
        name: "",
        visible: 1,
        beverage: "",
        sort_order: "",
        btn_color: "",
        text_color: "#FFFFFF",
        translated_name: "",
        order_types: [],
        order_type_ids: [],
        menu_groups_ids: [],
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["name", "translated_name"],
      headers: [
        { text: "#", value: "id", sortable: true, widh: 10 },
        { text: "Name", value: "name", sortable: true },
        { text: "Translated name", value: "translated_name", sortable: true },
        { text: "Sort order", value: "sort_order", sortable: true },
        { text: "Button", value: "cat_button" },
        { text: "Visible In", value: "order_types" },
        { text: "Action", value: "action" },
      ],
      selected_order_types: [],
      colors: [
        "#2C7BE5",
        "#727CF5",
        "#6B5EAE",
        "#FF679B",
        "#E63757",
        "#FD7E14",
        "#F5803E",
        "#00D27A",
        "#02A8B5",
        "#27BCFD",
        "#000",
        "#748194",
        "#4D5969",
      ],
    };
  },
  created() {
    // this.forceReload();
    this.getData();
    this.getAllOrderTypes();
    // this.getMenuItemSize();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  methods: {
    addNew() {
      this.formData = {
        name: "",
        visible: 1,
        beverage: "",
        sort_order: "",
        btn_color: "",
        text_color: "#FFFFFF",
        translated_name: "",
        order_types: [],
        order_type_ids: [],
        menu_groups_ids: [],
      };
    },

    removeFromOrdertype(type_id) {
      this.formData.order_type_ids = this.formData.order_type_ids.filter(
        (el) => el != type_id
      );
      console.log(this.formData.order_type_ids);
    },
    addToOrdertype(type_id) {
      console.log(this.formData.order_type_ids);
      this.formData.order_type_ids.push(type_id);
      console.log(this.formData.order_type_ids);
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    editItem(resource) {
      console.log(resource);
      this.formData = resource;
    },
    setColor(color) {
      console.log(color);
      this.formData.btn_color = color;
    },
    groupSelected(e) {
      this.formData.group_id = e.target.value;
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.visible = !this.formData.visible;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.name.$touch();
      this.v$.formData.translated_name.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      MenuCategoryService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          let error_log = "";
          Object.entries(error.response.data.errors).forEach(([key, value]) => {
            error_log += `${value},`;
            console.log(value);
          });
          toast.error(error_log || error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.formData.text_color = "#FFFFFFF";
      this.$store.commit("SET_LOADING", true);
      MenuCategoryService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          toast.error(error.response.data.errors[0] || error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        MenuCategoryService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
              $("#edit-modal" + resource.id)
                .removeClass("fade")
                .hide();
              $(".modal-backdrop").remove();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return MenuCategoryService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
